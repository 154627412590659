import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import Title from '../components/title';
import Image from 'gatsby-image';
import renderHtml from '../utils/renderHtml';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Lightbox from '../components/Lightbox';

const KURS_QUERY = graphql`
  {
    allContentfulFoerderklasse {
      nodes {
        id
        titel
        kategorie
        beschreibung {
          json
        }
        bilder {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;

const FoerderklassePage: FC = () => {
  const data = useStaticQuery(KURS_QUERY).allContentfulFoerderklasse.nodes;
  const kurs = data
    // just a security measure in case the title gets mispelled
    .filter(n => n.titel.toLowerCase().includes('foerderklasse') || n.titel.toLowerCase().includes('förderklasse'))[0];
  return (
    <Layout location={Routes.FOERDERKLASSE}>
      <SEO title="Team" />
      <div
        className="pt-8 mb-12 md:px-8 flex justify-center flex-col items-center mx-auto"
        style={{ maxWidth: '84rem' }}
      >
        <Title className="mb-4 text-center">Förderklasse / Open Class</Title>
        <div className="transition-all transition-250 hover:shadow-xl mt-8 shadow-lg rounded xl:flex xl:flex-row xl:-mx-8 xl:justify-center xl:w-full">
          <Lightbox galleryButtonVisible={false} showLeftRightButtons youtubeUrls={[]} images={kurs.bilder.map(k => k.fluid)} className="max-w-2xl xl:w-1/2 mx-auto"  />
          <div className="px-4 xl:w-1/2 xl:mx-8 xl:-mt-6">
            <div className="text-gray-700 py-4 markdown-body">{documentToReactComponents(kurs.beschreibung.json)}</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FoerderklassePage;
